
import Vue from 'vue';

export default Vue.extend({
  name: 'commonConnections',
  components: {
    ConnectionItem: () => import('./connectionItem.vue'),
  },
  props: ['searchResults'],
  data: (): {
    loading: boolean;
    expandedState: Array<number>;
  } => ({
    loading: false,
    expandedState: [],
  }),
  methods: {
    removeItem(companyId: string): void {
      this.$emit('removeItem', companyId);
    },
    addItem(connection: unknown, accountName: string): void {
      this.$emit('addItem', connection, accountName);
    },
  },
});
